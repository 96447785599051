import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage1Varuosad from "./pages/Webpage1Varuosad";
import Webpage2K__psised from "./pages/Webpage2K__psised";
import Webpage3Lehte_ei_leitud from "./pages/Webpage3Lehte_ei_leitud";
import Webpage4Tellimus from "./pages/Webpage4Tellimus";
import Webpage5Toode from "./pages/Webpage5Toode";

var baseURL = 'https://laitserallypark.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1IiwianRpIjoiY2U4MWZkOWIxM2I5MjZlMmE1Njc2NjJmNzUxZTE4OTZmNTRiNjM4OGE4Yzg2YzFjNTQ5OTgzODNlNzRiOWFjYmFlZTUyMTc1NTcyMmQwMmMiLCJpYXQiOjE3MzcwMzA3NjQuOTEzMjUxLCJuYmYiOjE3MzcwMzA3NjQuOTEzMjU4LCJleHAiOjE3MzcxMTcxNjQuOTA4OTA4LCJzdWIiOiI5MSIsInNjb3BlcyI6W119.VDrbml4B_iLmCwaKH1oi5WAPfq0mWqeKuVms-Vn3mjzPm_E2iaEJCWvJn7LWqKOzAkzv0kyKi1HV4QbfWa0vBP3Zbw8OV_tEcUNQkPfVe8zRBPdSKdP7-9xo8Fny5A0w-SI2oaWHZWgHXaL5siS6p92jJt8LDDAfW-p38g1zMVi5Yya2eToNLI94xA7cZskuBmEE7N3qNLTxj9V9zYPC2cNxVYYDHo0fKbucUIhhefYPpEI6b7iH9FWOrEMkFUtD4CjzKSbx9B3PeumntY8Zp-84HFgkRDQ98mNLLfhqoD2ootpvrzE514V-lkv9HWKIv6bDEIJXhSBSNpz0FluMbauSaSCWPDR61eQExO3Vz31nuu1UUsJaQfTWdDT_fCchvaxIJTcV4VyaaA2Jj4hlx4xdYkNHcN2vX5VJ1ug01AAZd16ZuhcNIqQ0lejZiRCumhocjBjSD6umC4FUtb37dAJRGi-JuMQp-vBqbGW1Gn9y9cvSSvSX-qoyjteIm2MIOuxeeT-E6ULXC4s6C8F9ONs0dZGkXEsTFwVVh-HnwhYmZ2WB95JVeV8KOnkyoZP4AftXRjpNCdYcmJKOgiEGLfDP0LW2d2OkPGJgey3uNTIKbWxeCslNbD53VCm657v2Pi084hMXeTuk0KSxpKG4k5B8wQbqCPQS5aeeY5jjexU',
    'Access-api-key': "eyJpdiI6Iit4bnRWVi9kUWFLWnV2d1Ziak5kRWc9PSIsInZhbHVlIjoicitTT1cvNytYS1dqVGdJZmN2dlkxdz09IiwibWFjIjoiNDY3ZDY0YmM3YzEwMzZiZTFmNzc3MmQyODg2NDU0ZmE2MWQxZmE4M2E2YzJiZTRkM2I0ZjJjYmYzYmU3NjE0MSIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6IllEd0dmRDd5V2ZMUGp1MXAxVlhuMUE9PSIsInZhbHVlIjoidWROS3BHVkhxNEhOdlptMjlGSkxHdz09IiwibWFjIjoiZGViYjY5OTNjZDNjMDExMjI5ZmVlNTlmNDFmMDg5NmRiNmYzZGI4ZDVkYTEzOTM0ZTdiZDNiZmFkYTRmOGY2ZCIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: ""
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/et" element={<Webpage1Varuosad />} exact />;
                                                                    <Route path="/et/kupsised" element={<Webpage2K__psised />} exact />;
                                                                    <Route path="/et/*" element={<Webpage3Lehte_ei_leitud />} exact />;
                                                <Route path="*" element={<Webpage3Lehte_ei_leitud />} exact />;
                                                                    <Route path="/et/tellimus" element={<Webpage4Tellimus />} exact />;
                                                                    <Route path="/et/toode/:id" element={<Webpage5Toode />} exact />;
                                                                
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}